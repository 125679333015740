<template>
  <div>
    <div>

      <h3 class="card-label">
        {{ $t('MENU.cash_closing') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.balances') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th>{{ $t('pos_session.balance') }}</th>
                <th>{{ $t('pos_session.currency') }}</th>
                <th>{{ $t('pos_session.confirmed_amount') }}</th>
                <th></th>
              </tr>

              </thead>
              <tbody>
              <template v-if="confirm_treasury_balance_after.length > 0">
                <tr v-for="(row, index) in confirm_treasury_balance_after" :key="index">
                  <td><input v-model="row.balance" :disabled="true" class="form-control" min="0.01" step="0.01"
                             type="number"></td>
                  <td>
                    <select id="" v-model="row.currency_id" :disabled="true" class="custom-select" name="">
                      <option v-for="cc in currencies" :key="cc.id" :value="cc.id">{{ cc.name }}</option>
                    </select>
                  </td>
                  <td>
                    <input v-model="row.confirmed_amount"
                           :class="validation && validation['confirm_treasury_balance_after.'+index+'.confirmed_amount'] ? 'is-invalid' : ''"
                           class="form-control" min="0.01" step="0.01"
                           type="number"
                    >
                    <span v-if="validation && validation['confirm_treasury_balance_after.'+index+'.confirmed_amount']"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation['confirm_treasury_balance_after.' + index + '.confirmed_amount'][0] }}
                    </span>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="4">{{ $t('no_balances') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.details_currency') }}</h6>
            <button class="btn btn-primary" type="button" @click="addMoreInRepeater()">{{ $t('add_more') }}</button>
          </div>

          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addMoreInRepeater()"
                   @keyup.alt.46="removeFromRepeater(0)">
              <thead>
              <tr>
                <th style="width:25%">{{ $t('pos_session.cash_category') }}</th>
                <th style="width:10%">{{ $t('pos_session.cash_category_no') }}</th>
                <th style="width:15%">{{ $t('pos_session.cash_category_amount') }}</th>
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in cash_category" :key="index">
                <td>
                  <select v-model="row.cash_category_id"
                          :class="validation && validation['cash_category.'+index+'.cash_category'] ? 'is-invalid' : ''"
                          class="custom-select"
                          name="">
                    <option v-for="(ca, index) in cash_category_list_current_currency" :key="index" :value="ca.id">
                      {{ ca.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_id']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.cash_category_id'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.cash_category_no"
                         :class="validation && validation['cash_category.'+index+'.cash_category_no'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_no']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.cash_category_no'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.amount"
                         :class="validation && validation['cash_category.'+index+'.amount'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.amount']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.amount'][0] }}
                  </span>
                </td>


                <td class="text-center">
                  <v-icon small style="color: #dc3545;" @click="removeFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div v-if="is_foreign_currency" class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_session.details_other_currencies') }}</h6>
            <button class="btn btn-primary" type="button" @click="addMoreInRepeaterOther">{{ $t('add_more') }}</button>
          </div>

          <div class="bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addMoreInRepeaterOther"
                   @keyup.alt.46="removeFromRepeaterOther(0)">
              <thead>
              <tr>
                <th style="width:25%">{{ $t('pos_session.cash_category') }}</th>
                <th style="width:10%">{{ $t('pos_session.cash_category_no') }}</th>
                <th style="width:15%">{{ $t('pos_session.cash_category_amount') }}</th>
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in cash_category_other" :key="index">
                <td>
                  <select v-model="row.cash_category_id"
                          :class="validation && validation['cash_category.'+index+'.cash_category'] ? 'is-invalid' : ''"
                          class="custom-select"
                          name="">
                    <option v-for="(ca, index) in cash_category_list" :key="index" :value="ca.id">
                      {{ ca.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_id']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.cash_category_id'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.cash_category_no"
                         :class="validation && validation['cash_category.'+index+'.cash_category_no'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.cash_category_no']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.cash_category_no'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.amount"
                         :class="validation && validation['cash_category.'+index+'.amount'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['cash_category.'+index+'.amount']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['cash_category.' + index + '.amount'][0] }}
                  </span>
                </td>


                <td class="text-center">
                  <v-icon small style="color: #dc3545;" @click="removeFromRepeaterOther(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-cash-closing",
  data() {
    return {
      mainRoute: 'pos/pos-sessions',
      mainSubRoute: 'pos/pos-session/close_cash',
      mainRouteDependency: 'base/dependency',
      mainRouteTreasury: 'finances/treasuries',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      is_foreign_currency: false,

      device_id: null,
      session_id: null,

      isEditing: false,
      validation: null,
      device: null,
      treasury_id: null,

      currencies: [],

      cash_category_list_current_currency: [],
      cash_category: [],
      cash_category_form: {cash_category_id: null, cash_category_no: null, amount: null, is_other_currency: 0},

      cash_category_list: [],
      cash_category_other: [],
      cash_category_form_other: {cash_category_id: null, cash_category_no: null, amount: null, is_other_currency: 1},

      confirm_treasury_balance_after: [],

    };
  },
  watch: {
    device_id: function (val) {
      if (val) {
        this.setRepeaterData(this.device_id);
      } else {
        this.cash_category_list_current_currency = [];
      }
    },
    // treasury_id: function (val) {
    //   if (val) {
    //     this.getBalancesTreasury(val);
    //   } else {
    //     this.confirm_treasury_balance_after = [];
    //   }
    // },
  },
  methods: {
    save() {
      // if (this.isEditing) {
      this.update();
      // } else {
      //     this.create();
      // }
    },

    // create() {
    //   ApiService.post(this.mainRoute, {
    //     cash_category: this.cash_category,
    //   }).then((response) => {
    //     this.validation = null;
    //     this.$successAlert(response.data.message);
    //     this.$router.push('/pos/pos-session');
    //   }).catch((error) => {
    //     this.$errorAlert(error);
    //     this.validation = error.response ? error.response.data.errors : null;
    //   });
    // },

    update() {
      let $cash_category = this.cash_category;
      $cash_category = $cash_category.filter((row) => {
        return row.amount !== 0 && row.cash_category_no !== 0;
      });
      let $cash_category_other = this.cash_category_other;
      $cash_category_other = $cash_category_other.filter((row) => {
        return row.amount !== 0 && row.cash_category_no !== 0;
      });
      let _cashing = [...$cash_category, ...$cash_category_other];
      ApiService.patch(this.mainSubRoute + '/' + this.idEdit, {
        cash_category: _cashing,
        confirm_treasury_balance_after: this.confirm_treasury_balance_after,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-session');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.is_foreign_currency = response.data.data.is_foreign_currency;
        this.session_id = response.data.data.session_id;
        this.device_id = response.data.data.device_id;
        this.treasury_id = response.data.data.treasury_id;
        if (response.data.data.confirm_treasury_balance_after && response.data.data.confirm_treasury_balance_after.length > 0) {
          this.confirm_treasury_balance_after = response.data.data.confirm_treasury_balance_after ? response.data.data.confirm_treasury_balance_after : [];
        } else {
          this.confirm_treasury_balance_after = response.data.data.confirm_treasury_balance ? response.data.data.confirm_treasury_balance : [];
        }
        // this.cash_category = response.data.data.cash_category.filter(row => row.is_other_currency == 0 && row.session_interval == 1);
        // this.cash_category_other = response.data.data.cash_category.filter(row => row.is_other_currency == 1 && row.session_interval == 1);

      });
    },
    setRepeaterData(device_id) {
      this.getCashCategoriesForCurrentCurrency(device_id).then(() => {
        this.cash_category = []
        for (let i = 0; i < this.cash_category_list_current_currency.length; i++) {
          this.addMoreInRepeater(i);
        }
      })
    },
    getCashCategories() {
      ApiService.get(this.mainRouteDependency + `/cash_categories`).then((response) => {
        this.cash_category_list = response.data.data;
      });
    },
    async getCashCategoriesForCurrentCurrency(id) {
      if (id)
        await ApiService.get(this.mainRouteDependency + `/cash_categories_by_device/` + id).then((response) => {
          this.cash_category_list_current_currency = response.data.data;
        });
    },
    // getBalancesTreasury(id) {
    //   if (id)
    //     ApiService.get(this.mainRouteTreasury + `/` + id).then((response) => {
    //       this.confirm_treasury_balance_after = response.data.data.items_list.map((row)=>{
    //         return {
    //           balance: row.balance,
    //           currency_id: row.currency_id,
    //           confirmed_amount: row.balance,
    //         }
    //       });
    //     });
    // },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    addMoreInRepeater(i = null) {
      if (i != null) {
        this.cash_category_form = {
          cash_category_id: this.cash_category_list_current_currency[i].id,
          cash_category_no: 0,
          amount: 0,
          is_other_currency: 0,
        };
        this.cash_category.push(this.cash_category_form);
      } else {
        this.cash_category_form = {
          cash_category_id: null,
          cash_category_no: null,
          amount: null,
          is_other_currency: 0,
        };
        this.cash_category.unshift(this.cash_category_form);
      }
    },
    removeFromRepeater(index) {
      this.cash_category.splice(index, 1);
    },

    addMoreInRepeaterOther() {
      this.cash_category_other.unshift(this.cash_category_form_other);
      this.cash_category_form_other = {
        cash_category_id: null,
        cash_category_no: null,
        amount: null,
        is_other_currency: 1
      };
    },
    removeFromRepeaterOther(index) {
      this.cash_category_other.splice(index, 1);
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.pos_sessions"),
      route: '/pos/pos-session'
    }, {title: this.$t("MENU.cash_closing")}]);
    this.getCurrencies();
    this.getData();
    this.getCashCategories();


  },
};
</script>


